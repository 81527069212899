.taobao {
  align-self: stretch;
  --taobao-color: #fe5600;
}

.label {
  color: var(--taobao-color);
  flex: none;
  background-color: unset;
  border: unset;
}

.search-btn {
  min-width: 80px;
}
